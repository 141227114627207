










import Vue from 'vue';
import PageLayout from '@/components/page/layout.vue';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import Component from 'vue-class-component';
import TableNavigation from '@/components/tables/navigation.vue';
import { AreaRecordsModule } from '@/store/areas/records';
import { areaRecordsModule } from '@/store';
import AreaForm from '@/components/areas/areas/form/index.vue';
import { Watch } from 'vue-property-decorator';

const schema: ITableColumn[] = [
  { label_i18n: 'id', prop: 'id', width: 80 },
  { label_i18n: 'image', width: '176', component: TableComponentNames.PolygonThumbnailObjectPreview },
  {
    label_i18n: 'description',
    prop: 'description',
    component: TableComponentNames.AreaRecordDescription,
    'min-width': 200
  }
];

@Component({
  components: { AreaForm, TableNavigation, PageLayout, TableGenerator }
})
export default class AreaRecords extends Vue {
  get state(): AreaRecordsModule {
    return areaRecordsModule;
  }

  get schema(): ITableColumn[] {
    return schema;
  }

  get title() {
    const area = this.state.items[0]?.area || '—';
    const trigger = this.state.filter?.current?.area_trigger;
    return this.$tf('areas_records', { area, trigger });
  }

  @Watch('title')
  titleChanged(title) {
    const area = this.state.items[0]?.area || '—';
    const trigger = this.state.filter?.current?.area_trigger;
    this.$store.state.dialog.reports.i18nData = { area, trigger };
    document.title = title;
  }

  mounted() {
    this.state.setAutoUpdate(true);
  }

  beforeDestroy() {
    this.state.setAutoUpdate(false);
  }

  rowClickHandler({ item }) {}
}
